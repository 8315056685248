@color_theme: #02adda;

.modal_mask {
  position: fixed;
  z-index: 98;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);

  :global {
    animation: fadeIn 600ms;
    animation-fill-mode: both;
  }
}

.modal_wrap {
  position: fixed;
  z-index: 99;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.modal_box {
  width: 550px;
  border-radius: 16px;
  background: #ffffff;
  overflow: hidden;

  :global {
    animation: zoomInDown 600ms;
    animation-fill-mode: both;
  }
}

.modal_header {
  padding: 0 24px;

  .title {
    text-align: center;
    line-height: 90px;
    font-size: 28px;
    margin: 0;
    font-weight: bold;
  }
}

.modal_body {
  padding: 24px;
  text-align: center;
}



.modal_footer {
  display: flex;

  .btn_cancel,
  .btn_confirm {
    flex: 1 0 50%;
    height: 90px;
    border: 0;
    background: #ffffff;
    transition: 100ms;
    font-size: 28px;
    font-weight: bold;

    &:active {
      font-size: 26px;
    }
  }

  .btn_cancel {
    color: #999999;
  }

  .btn_confirm {
    color: @color_theme;
  }


}

.input_wrap {
  width: 100%;
  padding: 6px 24px;
  background: #f3f3f3;
  margin-top: 28px;
  border-radius: 16px;

  >input[type="text"] {
    display: block;
    height: 60px;
    width: 100%;
    border: 0;
    background: none;
  }
}

.confirm_loading {
  margin-left: 16px;
  vertical-align: text-top;
}