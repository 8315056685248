.price {
  &.del {
    color: #999999;
    text-decoration: line-through;
  }
  span,
  i {
    vertical-align: baseline;
  }
  i {
    font-size: 60%;
    padding-right: 4px;
    font-style: normal;
  }
  span + i {
    padding-left: 4px;
    padding-right: 0;
  }
}
