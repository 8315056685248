.pic_item {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  > img {
    width: 100%;
    height: 100%;
  }
  .wait {
    width: 210px;
    height: auto;
    max-width: 80%;
  }
  .real {
    :global {
      animation: fadeIn 600ms;
      animation-fill-mode: both;
    }
  }
  .loading {
    animation: pic_load 1s linear infinite;
    font-size: 50px;
    @keyframes pic_load {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

.view_img {
  display: block;
  max-width: 80vw;
  max-height: 80vh;
}
