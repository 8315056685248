/* 单行溢出 */
/*多行溢出 手机端使用*/
.rate_wrap {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
}
.rate_wrap > span {
  flex: 0 0 auto;
  font-size: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% auto;
}
.rate_wrap > span ~ span {
  margin-left: 6px;
}
.rate_wrap > span.star {
  width: 32px;
  height: 32px;
  background-image: url('~@/assets/images/rate_star_off.png');
}
.rate_wrap > span.star.active {
  background-image: url('~@/assets/images/rate_star_on.png');
}
.rate_wrap > span.star.half {
  background-image: url('~@/assets/images/rate_star_half.png');
}
.rate_wrap > span.circle {
  width: 40px;
  height: 40px;
  background-image: url('~@/assets/images/rate_circle_off.png');
}
.rate_wrap > span.circle.active {
  background-image: url('~@/assets/images/rate_circle_on.png');
}
.rate_wrap.small > span {
  width: 26px;
  height: 26px;
}
.rate_wrap.small > span.circle {
  width: 36px;
  height: 36px;
}
