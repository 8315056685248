@box_radius: 8px;

.defaultBox() {
  display: inline-block;
  max-width: 600px;
  min-width: 200px;
  padding: 16px;
  border-radius: @box_radius;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.1);
}

.message_wrap {
  position: fixed;
  z-index: 1010;
  top: 32px;
  left: 0;
  width: 100%;
  pointer-events: none;
}

.message_box {
  text-align: center;
  margin-bottom: 8px;
  font-size: 28px;
  line-height: 40px;
  word-wrap: break-word;
  word-break: break-all;
  :global {
    animation: slideInDown 600ms;
  }
  > span {
    vertical-align: top;
    font-feature-settings: 'tnum';
  }
  .icon {
    position: relative;
    margin-right: 8px;
    display: inline-block;
    vertical-align: top;
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.box_info {
  .defaultBox();
  background: #ffffff;
  color: #333333;
  .icon {
    background-image: url('./icons/info.png');
  }
}

.box_success {
  .defaultBox();
  background: #ffffff;
  color: #333333;
  .icon {
    background-image: url('./icons/success.png');
  }
}

.box_warn {
  .defaultBox();
  background: #ffffff;
  color: #333333;
  .icon {
    top: 2px;
    background-image: url('./icons/warn.png');
  }
}

.box_error {
  .defaultBox();
  background: #ffffff;
  color: #ef0a4f;
  .icon {
    background-image: url('./icons/error.png');
  }
}

.box_loading {
  .defaultBox();
  background: #ffffff;
  color: #333333;
  .icon_loading {
    position: relative;
    top: -4px;
    margin-right: 16px;
  }
}
