@color_theme: #02adda;

.node_mask {
  position: fixed;
  z-index: 98;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  :global {
    animation: fadeIn 600ms;
    animation-fill-mode: both;
  }
}

.node_main {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99;
  width: 100%;
  border-radius: 16px 16px 0 0;
  background: #ffffff;
  :global {
    animation: fadeInUp 600ms;
    animation-fill-mode: both;
  }
}

.node_box {
  position: relative;
  overflow: hidden;
  height: 490px;
  display: flex;
  &::before {
    content: "";
    display: block;
    position: absolute;
    z-index: 2;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.9) 0px,
      rgba(255, 255, 255, 0.6) 210px,
      rgba(255, 255, 255, 0) 210px,
      rgba(255, 255, 255, 0) 280px,
      rgba(255, 255, 255, 0.6) 280px,
      rgba(255, 255, 255, 0.9) 470px
    );
  }
  &::after {
    content: "";
    pointer-events: none;
    display: block;
    width: 100%;
    height: 70px;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    position: absolute;
    z-index: 3;
    top: 210px;
    left: 0;
  }
}

.picker_box {
  flex: 1 0 auto;
  height: 100%;
  overflow: hidden;
  text-align: center;
  > div > div {
    height: 70px;
    line-height: 70px;
  }
}

.node_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 0 24px;
  border-bottom: 1px solid #e6e6e6;
  .confirm {
    border: 0;
    background: none;
    color: @color_theme;
    padding: 0 16px;
  }
}
