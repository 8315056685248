@color_theme: #02adda;

.radio_wrap {
  .lt {
    margin-right: 16px;
  }
  .rt {
    margin-left: 16px;
  }
}

.radio_item {
  position: relative;
  width: 1em;
  height: 1em;
  appearance: none;
  vertical-align: middle;
  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #c9c9c9;
    background: #ffffff;
    border-radius: 100%;
  }
  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0.28em;
    top: 0.2em;
    width: 0.5em;
    height: 0.22em;
    border-left: 0.1em solid #ffffff;
    border-bottom: 0.1em solid #ffffff;
    transform: rotate(-45deg);
    animation: radio_checked 200ms;
    @keyframes radio_checked {
      0% {
        transform: rotate(-45deg) scale(0);
        opacity: 0;
      }
      90% {
        transform: rotate(-45deg) scale(1.1);
        opacity: 1;
      }
      100% {
        transform: rotate(-45deg) scale(1);
        opacity: 1;
      }
    }
  }
  &:checked {
    &::before {
      background: @color_theme;
      border-color: @color_theme;
    }
  }
}
