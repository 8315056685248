@color_theme: #02adda;

.loader_colors {
  position: relative;
  width: 2.5em;
  height: 2.5em;
  transform: rotate(165deg);
  margin: 0 auto;
}
.loader_colors:before,
.loader_colors:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 0.5em;
  height: 0.5em;
  border-radius: 0.25em;
  transform: translate(-50%, -50%);
}
.loader_colors:before {
  animation: before 2s infinite;
}
.loader_colors:after {
  animation: after 2s infinite;
}

@keyframes before {
  0% {
    width: 0.5em;
    box-shadow: 1em -0.5em rgba(225, 20, 98, 0.75),
      -1em 0.5em rgba(111, 202, 220, 0.75);
  }
  35% {
    width: 2.5em;
    box-shadow: 0 -0.5em rgba(225, 20, 98, 0.75),
      0 0.5em rgba(111, 202, 220, 0.75);
  }
  70% {
    width: 0.5em;
    box-shadow: -1em -0.5em rgba(225, 20, 98, 0.75),
      1em 0.5em rgba(111, 202, 220, 0.75);
  }
  100% {
    box-shadow: 1em -0.5em rgba(225, 20, 98, 0.75),
      -1em 0.5em rgba(111, 202, 220, 0.75);
  }
}
@keyframes after {
  0% {
    height: 0.5em;
    box-shadow: 0.5em 1em rgba(61, 184, 143, 0.75),
      -0.5em -1em rgba(233, 169, 32, 0.75);
  }
  35% {
    height: 2.5em;
    box-shadow: 0.5em 0 rgba(61, 184, 143, 0.75),
      -0.5em 0 rgba(233, 169, 32, 0.75);
  }
  70% {
    height: 0.5em;
    box-shadow: 0.5em -1em rgba(61, 184, 143, 0.75),
      -0.5em 1em rgba(233, 169, 32, 0.75);
  }
  100% {
    box-shadow: 0.5em 1em rgba(61, 184, 143, 0.75),
      -0.5em -1em rgba(233, 169, 32, 0.75);
  }
}

.loader_bounce,
.loader_circle {
  display: inline-block;
  vertical-align: middle;
}

.loader_bounce {
  > div {
    width: 2em;
    height: 1em;
    display: flex;
    align-items: center;
    justify-content: space-around;
    span {
      display: block;
      height: 100%;
      flex: 0 0 auto;
      width: 6px;
      margin: auto;
      border-radius: 6px;
      background-color: #ffffff;
      -webkit-animation: stretchdelay 1.2s infinite ease-in-out;
      animation: stretchdelay 1.2s infinite ease-in-out;
      &:nth-child(2) {
        -webkit-animation-delay: -1.1s;
        animation-delay: -1.1s;
      }

      &:nth-child(3) {
        -webkit-animation-delay: -1s;
        animation-delay: -1s;
      }

      &:nth-child(4) {
        -webkit-animation-delay: -0.9s;
        animation-delay: -0.9s;
      }

      &:nth-child(5) {
        -webkit-animation-delay: -0.8s;
        animation-delay: -0.8s;
      }
    }
  }
  &.dark span {
    background-color: #999999;
  }
  @keyframes stretchdelay {
    0%,
    40%,
    100% {
      transform: scaleY(0.4);
      -webkit-transform: scaleY(0.4);
    }

    20% {
      transform: scaleY(1);
      -webkit-transform: scaleY(1);
    }
  }
}

.loader_circle {
  width: 1em;
  height: 1em;
  border: 3px solid @color_theme;
  border-radius: 1em;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  animation: circle_load 1s infinite linear;
  &.dark {
    border-top-color: #999999;
    border-bottom-color: #999999;
  }
  &.white {
    border-top-color: #ffffff;
    border-bottom-color: #ffffff;
  }
  @keyframes circle_load {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
