/* 单行溢出 */
/*多行溢出 手机端使用*/
.unfind {
  height: 100%;
  padding: 200px 100px 0;
  background: #ffffff;
}
.unfind .icon {
  display: block;
  width: 200px;
  height: 200px;
  background: url("~@/assets/images/404.png") center no-repeat;
  background-size: 200px auto;
  margin-left: -26px;
}
.unfind h3 {
  font-size: 60px;
  margin: 60px 0 10px;
}
.unfind .tips {
  color: #999999;
  padding: 50px 0 0;
  line-height: 60px;
}
.unfind button {
  position: absolute;
  bottom: 100px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 160px;
  height: 60px;
  border: 0;
  background: transparent;
  color: #02adda;
}
