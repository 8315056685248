.loading_wrap {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: #ffffff;
  padding-bottom: 20vh;
  .desc{
    margin-top: 36px;
    font-size: 28px;
  }
}
