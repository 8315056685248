/*格式化样式*/
@import "~animate.css";
body,
div,
dl,
dt,
dd,
ol,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td,
button {
  margin: 0;
  padding: 0;
  outline: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
fieldset,
img {
  border: 0;
}
address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-style: normal;
  font-weight: normal;
}
ol,
ul,
li {
  list-style: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}
q:before,
q:after {
  content: '';
}
abbr,
acronym {
  border: 0;
}
a {
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
/* 重写样式 */
select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
}
select::-ms-expand {
  display: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/* chrome */
input[type='number'] {
  -moz-appearance: textfield;
}
/* firefox */
:root {
  font-size: 13.3333vmin;
}
@media (min-width: 750px) {
  :root {
    font-size: 100px;
  }
}
* {
  box-sizing: border-box;
}
html,
body,
#root {
  height: 100%;
}
body {
  font-size: 28px !important;
  background: #f8f8f8;
}
input,
button,
textarea {
  font-size: 28px;
  -webkit-appearance: none;
}
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.router-wrapper,
.router-page {
  position: relative;
  height: 100%;
}
.router-wrapper {
  overflow: hidden;
}
/* 路由前进时的入场/离场动画 */
.forward-enter {
  position: absolute;
  width: 100vw;
  overflow: hidden;
  opacity: 0;
  transform: scale(1.1);
}
.forward-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: 300ms linear;
  transition-property: opacity, transform;
}
.forward-exit {
  position: absolute;
  width: 100vw;
  overflow: hidden;
  opacity: 0;
  transform: scale(1);
}
.forward-exit-active {
  opacity: 0;
  transform: scale(1.1);
  transition: 300ms linear;
  transition-property: opacity, transform;
}
/* 路由后退时的入场/离场动画 */
.back-enter {
  position: absolute;
  width: 100vw;
  overflow: hidden;
  opacity: 0;
  transform: scale(1.1);
}
.back-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: 300ms linear;
  transition-property: opacity, transform;
}
.back-exit {
  opacity: 0;
  transform: scale(1);
}
.back-exit-active {
  opacity: 0;
  transform: scale(1.1);
  transition: 300ms linear;
  transition-property: opacity, transform;
}
.ume_foot_modal-enter {
  opacity: 0;
  transform: translateY(100%);
}
.ume_foot_modal-enter-active {
  opacity: 1;
  transform: translateY(0%);
  transition: opacity 500ms, transform 500ms;
}
.ume_foot_modal-exit {
  opacity: 1;
}
.ume_foot_modal-exit-active {
  opacity: 0;
  transform: translateY(100%);
  transition: opacity 500ms, transform 500ms;
}
[data-type="list"] {
  animation: fadeInUp 600ms;
  animation-fill-mode: both;
}
.header_tab {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header_tab > a {
  padding: 0 30px;
  font-size: 28px;
  color: #999999;
  font-weight: bold;
}
.header_tab > a.active {
  color: #333333;
  font-size: 34px;
}
.tab_hidden {
  height: 90px;
}
.header_tab {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header_tab > a {
  position: relative;
  padding: 0 30px;
  font-size: 28px;
  color: #999999;
  font-weight: bold;
}
.header_tab > a.active {
  color: #02adda;
  font-size: 34px;
}
