/* 单行溢出 */
/*多行溢出 手机端使用*/
.footer_hidden {
  height: 132px;
}
.footer_main {
  position: fixed;
  z-index: 9;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffff;
  height: 132px;
  box-shadow: 0 -3px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-around;
}
.footer_main .item {
  color: #999999;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  transition: 0.5s;
  transition-property: transform;
}
.footer_main .item:global(.active) {
  color: #cb2b2a;
}
.footer_main .item:global(.active) .on {
  display: block;
}
.footer_main .item:global(.active) .off {
  display: none;
}
.footer_main .item .on {
  display: none;
}
.footer_main .item > i {
  flex: 0 0 auto;
  width: 80px;
  height: 80px;
  background-size: 78px 80px;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 6px;
}
.footer_main .icon {
  font-size: 40px;
  margin-bottom: 6px;
}
.footer_main .icon_store {
  background-image: url('~@/assets/images/spring_nav_store_off.png');
}
.footer_main .item:global(.active) .icon_store {
  background-image: url('~@/assets/images/spring_nav_store_on.png');
}
.footer_main .icon_discover {
  background-image: url('~@/assets/images/spring_nav_discover_off.png');
}
.footer_main .item:global(.active) .icon_discover {
  background-image: url('~@/assets/images/spring_nav_discover_on.png');
}
.footer_main .icon_mine {
  background-image: url('~@/assets/images/spring_nav_mine_off.png');
}
.footer_main .item:global(.active) .icon_mine {
  background-image: url('~@/assets/images/spring_nav_mine_on.png');
}
