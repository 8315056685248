/* 单行溢出 */
/*多行溢出 手机端使用*/
.action_mask {
  position: fixed;
  z-index: 88;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
}
.action_mask :global {
  animation: fadeIn 300ms;
}
.action_wrap {
  position: fixed;
  z-index: 89;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
}
.action_wrap :global {
  animation: fadeInUp 300ms;
}
.action_item {
  padding: 0 24px;
  line-height: 114px;
  background: #ffffff;
  text-align: center;
  font-size: 32px;
}
.action_item + .action_item {
  border-top: 1px solid #f3f3f3;
}
.action_cancle {
  height: 130px;
  width: 100%;
  border: 0;
  background: #ffffff;
  border-top: 20px solid #f3f3f3;
  font-size: 32px;
}
.action_hide .action_mask :global {
  animation: fadeOut 300ms;
  animation-fill-mode: both;
}
.action_hide .action_wrap :global {
  animation: fadeOutDown 300ms;
  animation-fill-mode: both;
}
