.switch {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 3em;
  height: 1.5em;
  border-radius: 1.5em;
  box-shadow: 0 0 0 1px #ebebeb;
  background: #ffffff;
  transition: 0.5s;
  transition-property: background;
  > i {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 1.5em;
    height: 1.5em;
    border-radius: 1.5em;
    background: #ffffff;
    box-shadow: 0 0.3em 0.6em 0 rgba(0, 0, 0, 0.1);
    transition: 0.3s;
    transition-property: left;
  }
  &.active {
    background: #02adda;
    > i {
      left: 1.5em;
    }
  }
}
